import {post,get,delet,put} from '../axios/requestAdmin';
import delay from "../helper/delay";

import { ztPost, ztGet, ztDelete, ztPut} from '../axios/requestAdmin4Zt';

//根据条件查询用户
export function queryMemberByCondition(data){
    return post('/r/Member_MemberInfo/queryMemberByCondition',data)
}
//根据 id 列表查询用户
export function queryMemberByIdList(data){
    return post('/r/Member_MemberInfo/queryMemberByIdList',data)
}

export function queryMemberByConditionByZt(data){
    return ztPost(`/zt-api-admin/user-center/admin/member/query`,data)
}