import {post,get,delet,put} from '../axios/request';
import delay from "../helper/delay";
//分页获取渠道列表
export function getChannelList(data){
    return post('/r/Manage_Config/getChannelList',data)
}
//添加渠道
export function addChannel(data){
    return post('/r/Manage_Config/addChannel',data)
}
//编辑渠道
export function editChannel(data){
    return post('/r/Manage_Config/editChannel',data)
}
//分页获取业务方列表
export function getClientList(data){
    return post('/r/Manage_Config/getClientList',data)
}
//添加业务方
export function addClient(data){
    return post('/r/Manage_Config/addClient',data)
}
//编辑业务方
export function editClient(data){
    return post('/r/Manage_Config/editClient',data)
}
//分页获取供应商配置列表
export function getMerchantList(data){
    return post('/r/Manage_Config/getMerchantList',data)
}
//获取所有权限信息
export function getAllAuthList(data){
    return get('/r/Manage_Authority/getAllAuthList',data)
}
//查看已经配置的用户权限列表
export function getAuthList(data){
    return post('/r/Manage_Authority/getAuthList',data)
}
//编辑供应商配置
export function editMerchant(data){
    return get('/r/Manage_Config/editMerchant',data)
}
//添加供应商配置列表
export function addMerchant(data){
    return get('/r/Manage_Config/addMerchant',data)
}
//获取微信子商户列表
export function getMerchantWePayList(data){
    return post('/r/Manage_PayMerchant/getMerchantWePayList',data)
}
//修改微信商户独立收款配置状态
export function setMerchantIndependent(data){
    return post('/r/Manage_PayMerchant/setMerchantIndependent',data)
}
//编辑微信子商户
export function editMerchantWePay(data){
    return post('/r/Manage_PayMerchant/editMerchantWePay',data)
}
//添加微信子商户
export function addMerchantWePay(data){
    return post('/r/Manage_PayMerchant/addMerchantWePay',data)
}
//获取支付宝子商户列表
export function getMerchantAliPayList(data){
    return post('/r/Manage_PayMerchant/getMerchantAliPayList',data)
}
//获取用户权限详情
export function getMemBerAuthList(data){
    return post('/r/Manage_Authority/getMemBerAuthList',data)
}
//编辑用户权限列表
export function editAuth(data){
    return post('/r/Manage_Authority/editAuth',data)
}
//删除渠道
export function delChannel(data){
    return post('/r/Manage_Config/delChannel',data)
}
//修改渠道状态
export function setChannelState(data){
    return post('/r/Manage_Config/setChannelState',data)
}
//删除业务方
export function delClient(data){
    return post('/r/Manage_Config/delClient',data)
}

//修改业务方状态
export function setClientState(data){
    return post('/r/Manage_Config/setClientState',data)
}

//获取百度配置列表
export function getBaiDuConfigList(data){
    return post('/r/Manage_PayMerchant/getBaiDuConfigList',data)
}
//添加百度配置
export function addBaiDuConfig(data){
    return post('/r/Manage_PayMerchant/addBaiDuConfig',data)
}
//删除百度配置
export function delBaiDuConfig(data){
    return post('/r/Manage_PayMerchant/delBaiDuConfig',data)
}
//刷新百度配置
export function refreshBaiDuConfig(data){
    return post('/r/Manage_PayMerchant/refreshBaiDuConfig',data)
}

//获取其他配置列表
export function getCommonConfigList(data){
    return get('/r/Manage_PayMerchant/getCommonConfigList',data)
}
//添加其他配置
export function addCommonConfig(data){
    return post('/r/Manage_PayMerchant/addCommonConfig',data)
}
//刷新其他配置
export function updateCommonConfig(data){
    return post('/r/Manage_PayMerchant/updateCommonConfig',data)
}
//删除其他配置
export function delCommonConfig(data){
    return post('/r/Manage_PayMerchant/delCommonConfig',data)
}
//获取支付渠道配置模板
export function getChannelConfigTemplate(data){
    return get('/r/Manage_PayMerchant/getChannelConfigTemplate', data);
}
//是否设置密码
export function isSetSecurityConf(data){
    return post('/r/Manage_SecurityConf/isSetSecurityConf',data)
}
//添加密码
export function addSecurityConf(data){
    return post('/r/Manage_SecurityConf/addSecurityConf',data)
}
//校验密码
export function checkSecurityConf(data){
    return post('/r/Manage_SecurityConf/checkSecurityConf',data)
}
//获取账本配置列表
export function getAccountBookConfigList(data){
    return post('/r/Manage_Config/getAccountBookConfigList',data)
}
//添加账本配置
export function addAccountBook(data){
    return post('/r/Manage_Config/addAccountBook',data)
}
//编辑账本配置
export function editAccountBook(data){
    return post('/r/Manage_Config/editAccountBook',data)
}
//支付配置可用性校验--被扫支付
export function microPayCheck(data){
    return post('/r/Manage_UsabilityCheck/microPayCheck',data)
}
